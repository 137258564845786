import { Empty } from "antd";
import React, { useRef, useState } from "react";

interface ITabContextProps {
  add: () => void;
  tabItems: any[];
  setItems: (data: any) => void;
  activeKey: any;
  setActiveKey: (data: any) => void;
  loading: boolean;
  setLoading: (data: any) => void;
}

export const TabContext = React.createContext({} as ITabContextProps);

const TabProvider = ({ children, items = [], addComponent }) => {
  const [activeKey, setActiveKey] = useState(items?.[0]?.key || null);
  const [tabItems, setItems] = useState(items);
  const newTabIndex = useRef(0);
  const [loading, setLoading] = useState<boolean>(false);

  const add = () => {
    const newActiveKey = `new_tab_form${newTabIndex.current++}`;
    const newPanes = [...tabItems];

    newPanes.push({
      label: `新增Tab${newTabIndex.current + 1}`,
      children: addComponent
        ? addComponent
        : () => (
            <Empty
              description={`新增tab【${newTabIndex.current}】无内容`}
            ></Empty>
          ),
      key: newActiveKey,
    });

    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  return (
    <TabContext.Provider
      value={{
        tabItems,
        setItems,
        add,
        activeKey,
        setActiveKey,
        loading,
        setLoading,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

export default TabProvider;
