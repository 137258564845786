import React, { useState, useEffect, useMemo } from "react";
import { Upload, Button, Form } from "antd";
import "./index.scss";

let _uploading = false;
let _uploadFiles = [];
let _count = 0;

// export const

const CustomUpload = (props) => {
  const {
    text = "上传文件",
    icon = <>+</>,
    fileList,
    listType = "text",
    max = 1,
  } = props;
  // const formInstance = Form.useFormInstance();

  const isDisabled =
    !fileList || (fileList && fileList.length === 0) ? false : true;
  const [onUploading, setUploadStatus] = useState(false);
  const [uploadFiles, setUploadFiles] = useState(props.fileList || []);

  _uploading = onUploading;

  const uploadButton = useMemo(() => {
    return (
      <>
        {onUploading ? <>上传中...</> : <>{icon}</>}
        {props.children ? (
          props.children
        ) : (
          <div className="ant-upload-text">{text ? text : "上传图片 "}</div>
        )}
      </>
    );
  }, [onUploading, icon]);

  const uploadButtonList = (
    <>
      {onUploading ? (
        <>上传中...</>
      ) : props.children ? (
        props.children
      ) : (
        <Button disabled={isDisabled}>{text ? text : "上传图片"}</Button>
      )}
    </>
  );

  useEffect(() => {
    if (!!fileList) {
      setUploadFiles(
        fileList.filter((item) => !item.status || item.status == "done")
      );
    }
  }, [fileList]);

  const _beforeUpload = (data) => {
    if (!_uploading) {
      props.onUploadChange && props.onUploadChange(null);
      setUploadStatus(true);
    }

    _count = _count + 1;

    return !props.beforeUpload || props.beforeUpload(data);
  };

  const _onSuccess = (res) => {
    if (_uploading) {
      setUploadStatus(false);
    }

    const _fileList = !!props.onBeforeSuccess
      ? props.onBeforeSuccess(res)
      : [res];

    _uploadFiles = _uploadFiles.concat(_fileList);

    //当全部上传完成之后清空当前上传的数组
    const uploadedFiles = [...uploadFiles, ..._uploadFiles];
    props.onChange && props.onChange(uploadedFiles.slice(0, max));
    setUploadFiles(uploadedFiles.slice(0, max));

    _uploadFiles.splice(0, _uploadFiles.length);
    _count = 0;

    props.onSuccess && props.onSuccess(res);
  };

  const _props = {
    ...props,
    onSuccess: _onSuccess,
    beforeUpload: _beforeUpload,
    fileList: uploadFiles,
  };

  const renderContent = () => {
    switch (listType) {
      case "picture":
        return (
          <Upload listType="picture" {..._props}>
            {
              uploadFiles.length >= max ? null : uploadButtonList // 单张图片
            }
          </Upload>
        );
      case "text":
        return (
          <Upload {..._props}>
            <Button icon={icon}>{text}</Button>
          </Upload>
        );
      default:
        return (
          <Upload className="upload-card" listType="picture-card" {..._props}>
            {
              uploadFiles.length >= max ? null : uploadButton //  多张图片/单张图片
            }
          </Upload>
        );
    }
  };

  return <>{renderContent()}</>;
};

export default CustomUpload;
