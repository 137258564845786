import React, { useImperativeHandle, useRef } from "react";
import { ITabFormProp } from "../../types";
import "./index.scss";
import TabProvider from "./tabContext";
import TabFormInternal from "./tabFormInternal";

const TabForm = (props: ITabFormProp, ref: React.MutableRefObject<any>) => {
  let _internalRef = useRef();
  useImperativeHandle(ref, () => _internalRef.current, []);
  const { items, addComponent, ...restProps } = props;
  return (
    <TabProvider items={items} addComponent={addComponent}>
      <TabFormInternal ref={_internalRef} addComponent={addComponent} {...restProps}></TabFormInternal>
    </TabProvider>
  );
};

export default React.forwardRef(TabForm);
