import { Button, Empty, Space, Spin, Tabs } from "antd";
import React, {
  createRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ITabFormProp } from "../../types";
import classNames from "classnames";
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { TabContext } from "./tabContext";
import { DraggableTabNode } from "./tabDragNode";

const TabFormInternal = (
  props: Omit<ITabFormProp, "items">,
  ref: React.MutableRefObject<any>
) => {
  const {
    addComponent,
    saveData,
    saveButtonText = "保存",
    cancelButtonText = "取消",
    actionButtonsPosition = "inline",
    onCancel,
    extraButtons,
    ...restProps
  } = props;

  const {
    add,
    setItems,
    tabItems,
    activeKey,
    setActiveKey,
    loading,
    setLoading,
  } = useContext(TabContext);

  const tabRefs = useRef([]);

  useImperativeHandle(
    ref,
    () => {
      return {
        genTabItems: (data) => {
          if (tabItems.length < data.length) {
            const _diff = data.length - tabItems.length;
            let _tabPaneItems = [...tabItems];
            let _data = data.slice(tabItems.length);

            for (let i = 0; i < _diff; i++) {
              const newActiveKey = `additional_tab_${i}`;

              _tabPaneItems.push({
                label: _data[i]?.label || `${i}`,
                forceRender: true,
                children: addComponent
                  ? addComponent
                  : () => <Empty description={`tab ${i}没有内容组件`}></Empty>,
                key: _data[i]?.key || newActiveKey,
              });
            }
            setItems(_tabPaneItems);
          } else {
            setItems(
              _tabItems.map((item, index) => {
                return {
                  ...item,
                  ...data[index],
                };
              })
            );
          }
        },
        setFieldsValue: (data) => {
          data.forEach((item, index) => {
            tabRefs.current[index]?.current?.setFieldsValue?.(item);
          });
        },
        getFieldsValue: () => {
          return tabRefs.current.map((item) => {
            return item.current.getFieldsValue();
          });
        },
        validateFields: async () => {
          let _isValid = true;

          for (let i = 0; i < tabRefs.current.length; i++) {
            _isValid =
              _isValid &&
              (await tabRefs.current[i].current.validateFields().then((res) => {
                return res;
              }));

            if (!_isValid) {
              setActiveKey(tabItems[i].key);
              break;
            }
          }

          return _isValid;
        },
      };
    },
    [tabItems]
  );

  //更改tab
  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const onEdit = (targetKey: string, action: "add" | "remove") => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };

  //移除
  const remove = (targetKey: string) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    tabItems.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });

    const newPanes = tabItems.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const onSave = async () => {
    let _isValid = true;

    for (let i = 0; i < tabRefs.current.length; i++) {
      _isValid =
        _isValid &&
        (await tabRefs.current[i].current.validateFields().then((res) => {
          return res;
        }));

      if (!_isValid) {
        setActiveKey(tabItems[i].key);
        break;
      }
    }
    if (!_isValid) return;

    setLoading(true);

    let _formDatas = tabRefs.current.map((item) => {
      return item.current.getFieldsValue();
    });

    await saveData(_formDatas);

    setLoading(false);
  };

  let _tabItems = useMemo(() => {
    let _refs = [];
    let _items = tabItems.map((item, index) => {
      const Component = item?.children || (() => <></>);
      let _ref = createRef();

      _refs.push(_ref);
      return {
        ...item,
        children: (
          <Component
            index={index}
            ref={_ref}
            setItems={setItems}
            items={tabItems}
          ></Component>
        ),
      };
    });

    tabRefs.current = _refs;

    return _items;
  }, [tabItems]);

  const _actionButtons = () => {
    if (!extraButtons)
      return [
        <Button type="default" onClick={onCancel}>
          {cancelButtonText}
        </Button>,
        <Button type="primary" onClick={() => onSave()}>
          {saveButtonText}
        </Button>,
      ];

    return [
      ...extraButtons([
        <Button key="btn-cancel" type="default" onClick={onCancel}>
          {cancelButtonText}
        </Button>,
        <Button key="btn-save" type="primary" onClick={() => onSave()}>
          {saveButtonText}
        </Button>,
      ]),
    ];
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    console.log(active.id, over.id);
    if (active.id !== over?.id) {
      setItems((prev) => {
        const activeIndex = prev.findIndex((i) => i.key.toString() === active.id.toString());
        const overIndex = prev.findIndex((i) => i.key.toString() === over?.id);

        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  return (
    <Spin spinning={loading}>
      <Space
        className={classNames(
          "tab-form",
          actionButtonsPosition && "fixed-actionbar-form"
        )}
        direction="vertical"
      >
        <Tabs
          items={_tabItems}
          onChange={onChange}
          onEdit={onEdit}
          activeKey={activeKey}
          centered={true}
          renderTabBar={(tabBarProps, DefaultTabBar) => (
            <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
              <SortableContext
                items={tabItems.map((i) => i.key)}
                strategy={horizontalListSortingStrategy}
              >
                <DefaultTabBar {...tabBarProps}>
                  {(node) => (
                    <DraggableTabNode {...node.props} key={node.key}>
                      {node}
                    </DraggableTabNode>
                  )}
                </DefaultTabBar>
              </SortableContext>
            </DndContext>
          )}
          {...restProps}
        ></Tabs>
        <Space
          direction="horizontal"
          style={{
            textAlign: "right",
            width: "100%",
            padding: "12px",
            justifyContent: "end",
          }}
          align="end"
          className={classNames(
            "form-actionbar-item",
            actionButtonsPosition === "fixed" && "form-actionbar-fixed"
          )}
        >
          {_actionButtons()}
        </Space>
      </Space>
    </Spin>
  );
};

export default React.forwardRef(TabFormInternal);
