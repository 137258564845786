import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { FormComponent } from "../common";
import { Button, FormInstance, Modal, Spin, message } from "antd";
import { IDrawerFormProps, IModalFormProps, IModalFormRef } from "../../types";

const ModalForm: React.FC<IModalFormProps> = React.forwardRef(
  (props: IModalFormProps, ref: React.Ref<IModalFormRef>): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(false);
    const formRef = useRef<FormInstance>({} as FormInstance);

    let {
      onOk,
      okButton: OkButton,
      onOpen = () => {},
      onCancel,
      resetOnClose,
      formItems = [],
      footer,
      title,
      closable = false,
      maskClosable = false,
      width,
      extraButtons,
      ...restProps
    } = props;

    const [Footer, setFooter] = useState<React.ReactNode>(<></>);
    const [spinStatus, setSpinStatus] = useState({
      loading: false,
      tip: "loading",
    });

    useImperativeHandle<IModalFormRef, IModalFormRef>(
      ref,
      () => ({
        setVisible,
        setSpinStatus,
        ...formRef.current,
      }),
      []
    );

    const closeModal = () => {
      setVisible(false);

      props?.onClose?.(formRef.current.getFieldsValue());

      if (props.resetOnClose) {
        formRef.current?.resetFields();
      }
    };

    //弹窗点击保存
    const onSave = async () => {
      await formRef.current.validateFields();

      const res = await props?.onOk?.(formRef.current.getFieldsValue());

      if (!!res) {
        return;
      }

      setVisible(false);
    };

    useEffect(() => {
      if (visible) {
        if (props.data) {
          // console.log("set data");
          formRef.current.setFieldsValue(props.data);
        }

        if (props.footer === false) {
          setFooter(<></>);
        } else if (props.footer && typeof footer !== "boolean") {
          setFooter(footer);
        } else if (props.disabled) {
          setFooter([
            <Button className="danger" key="cancel" onClick={closeModal}>
              取消
            </Button>,
          ]);
        } else {
          setFooter([
            <Button className="danger" key="cancel" onClick={closeModal}>
              {"取消"}
            </Button>,
            OkButton ? (
              typeof OkButton === "function" ? (
                <OkButton type="primary" key="ok" onClick={() => onSave()} />
              ) : (
                OkButton
              )
            ) : (
              <Button type="primary" key="ok" onClick={onSave}>
                {"保存"}
              </Button>
            ),
            ...(extraButtons || []),
          ]);
        }

        onOpen?.();
      } else {
        formRef.current?.resetFields?.();
      }
    }, [visible]);

    return (
      <Modal
        open={visible}
        maskClosable={maskClosable}
        destroyOnClose={true}
        onCancel={closeModal}
        title={props.title ?? " "}
        closable={closable}
        centered={true}
        cancelText="取消"
        cancelButtonProps={{ danger: true }}
        okText="保存"
        footer={Footer}
        onOk={onSave}
        width={width}
        forceRender={true}
      >
        <Spin spinning={spinStatus.loading} tip={spinStatus.tip}>
          <FormComponent
            ref={formRef}
            formItems={formItems}
            preserve={false}
            {...restProps}
          />
        </Spin>
      </Modal>
    );
  }
);

export default ModalForm;
