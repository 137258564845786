import { Button, Space, FormInstance } from "antd";
import React, { useEffect, useMemo, useImperativeHandle, useRef } from "react";

import { IFormProps } from "../../types";
import { FormComponent } from "../common";

const NormalFormDefault: IFormProps = {
  formItems: [],
  //   ref: null,
};

const NormalForm = React.forwardRef(
  (props: IFormProps, ref: any): JSX.Element => {
    props = { ...NormalFormDefault, ...props };

    const {
      formItems,
      data,
      actionButtons,
      showActionBar = true,
      onSave,
      onCancel,
      cancelButtonText = "取消",
      ...restProps
    } = props;
    const formRef = useRef<FormInstance>({} as FormInstance);

    const saveForm = async () => {
      await formRef.current.validateFields();

      onSave?.(formRef.current.getFieldsValue());
    };

    const cancelForm = async () => {
      await formRef.current.resetFields();
      onCancel?.();
    };

    let _buttons: Array<JSX.Element> = [
      <Button key={"save-button"} type="primary" htmlType={"submit"}>
        {props.saveButtonText || "保存"}
      </Button>,
      <Button
        key={"cancel-button"}
        className="danger"
        disabled={false}
        type="default"
        onClick={cancelForm}
      >
        {cancelButtonText}
      </Button>,
    ];

    const ActionButtons = () => {
      if (!showActionBar) return <></>;

      if (actionButtons) {
        return <Space>{actionButtons(_buttons, formRef.current)}</Space>;
      }

      return <Space>{_buttons}</Space>;
    };

    useImperativeHandle(
      ref,
      () => ({
        ...formRef.current,
      }),
      []
    );

    useEffect(() => {
      // console.log("form data", data);
      if (data) {
        formRef.current.setFieldsValue(data);
      }
      return () => {
        // console.log("reset");
        formRef.current?.resetFields?.();
      };
    }, [data]);

    return (
      <FormComponent
        ref={formRef}
        formItems={formItems}
        actionButtons={ActionButtons}
        onFinish={saveForm}
        {...restProps}
      />
    );
  }
);

export default NormalForm;
